function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }
function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { isEqual } from 'lodash';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { State, interpret } from 'xstate';
import { routeToFilters } from '~/apps/pu-links/infrastructure/routeToFilters';
import { SearchEngine } from '~/apps/search/domain/engine';
import { getSearchMachine } from '~/apps/search/domain/machine';
import { areHierarchiesDifferent, doRedirect, redirectOnMachineRedirecting, setLinkHeader, setSearchXkeyHeaders } from '~/apps/search/helpers';
import { CountsService } from '~/apps/search/infrastructure/CountsService';
import { FetchCampsitePriceSearchService } from '~/apps/search/infrastructure/FetchCampsitePriceSearchService';
import { FetchCampsiteSearchService } from '~/apps/search/infrastructure/FetchCampsiteSearchService';
import { isSearchUrlRoute } from '~/apps/search/isSearchUrlComponent';
import { priceSearchCampsiteEndpoint, searchCampsiteEndpoint } from '~/apps/search/schemas';
import { waitForInterpreterState } from '~/utility/xstate/waitForInterpreterState';
import { logger } from '../utility/logger';
import { useFacetsStore } from './useFacetsStore';
var isDev = process.env.NODE_ENV !== 'production';
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0;
    var v = c === 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
}
var storeId = 'searchStorePinia';
export var useSearchStore = defineStore(storeId, () => {
  var _routeToFilters$dates, _window, _window$$nuxt, _window$$nuxt$$pinia$, _window$$nuxt$$pinia$2, _window$$nuxt$$pinia$3;
  // used to track requests in logs
  var requestID = generateUUID();
  var reqId = ref(requestID);
  var searchMachine = getSearchMachine();
  var router = useRouter();
  var route = useRoute();
  // if (isDev)
  //   console.log('useSearchStore created', requestID, route.fullPath, route.meta)
  var nuxtApp = useNuxtApp();
  var isDesktop = nuxtApp.$isDesktop;
  var $gtm = nuxtApp.nuxt2Context.$gtm;
  var langCode = route.params.lang || 'en-gb';
  var excludeBase = isDesktop ? [] : ['maps'];
  var distanceUnit = nuxtApp.$i18n.localeInfo.distanceUnit;
  var abortController = new AbortController();
  var countsAbortController = new AbortController();
  var fetchOptions = {
    timeout: process.server ? 30000 : 60000
  };
  var fetchCampsiteSearchService = new FetchCampsiteSearchService(fetchOptions);
  var fetchCampsitePriceSearchService = new FetchCampsitePriceSearchService(fetchOptions);
  var engine = ref(0);
  var service = interpret(searchMachine.withConfig({
    services: {
      doPriceSearch: _ref => {
        var {
          filters
        } = _ref;
        return priceSearchCampsiteEndpoint.validate(args => fetchCampsitePriceSearchService.search(langCode, _objectSpread(_objectSpread({}, args), {}, {
          engine: engine.value
        }), $gtm, {
          signal: abortController.signal
        }))(filters);
      },
      doSearch: (_ref2, _ref3) => {
        var {
          filters
        } = _ref2;
        var {
          searchUrl
        } = _ref3;
        return searchCampsiteEndpoint.validate(args => fetchCampsiteSearchService.search(langCode, args, $gtm, searchUrl, {
          signal: abortController.signal
        }))(filters);
      }
    }
  }));
  // dated search has a sep counts service
  var countsService = new CountsService({
    logger: $gtm
  });
  // TODO: can we do away with the engine?
  var searchEngine = new SearchEngine(service);

  // reactive states
  // the search filters used to get a search result
  var searchFilters = ref(routeToFilters(route));

  // if (isDev) {
  //   console.log(
  //     'searchFilters',
  //     requestID,
  //     route.fullPath,
  //     JSON.stringify(searchFilters.value),
  //   )
  // }
  // the search filters used to get a count
  var countSearchFilters = ref(null);
  var isDated = ref(Boolean((_routeToFilters$dates = routeToFilters(route).dates) === null || _routeToFilters$dates === void 0 ? void 0 : _routeToFilters$dates.arrive));
  // use the stateStr to hydrate on client
  var stateStr = ref(process.client && ((_window = window) === null || _window === void 0 ? void 0 : (_window$$nuxt = _window.$nuxt) === null || _window$$nuxt === void 0 ? void 0 : (_window$$nuxt$$pinia$ = _window$$nuxt.$pinia.state) === null || _window$$nuxt$$pinia$ === void 0 ? void 0 : (_window$$nuxt$$pinia$2 = _window$$nuxt$$pinia$.value) === null || _window$$nuxt$$pinia$2 === void 0 ? void 0 : (_window$$nuxt$$pinia$3 = _window$$nuxt$$pinia$2.searchStorePinia) === null || _window$$nuxt$$pinia$3 === void 0 ? void 0 : _window$$nuxt$$pinia$3.stateStr) || '');
  var initialState = stateStr.value ? JSON.parse(stateStr.value) : service.initialState;
  var state = State.create(initialState);
  var context = ref(state.context);
  var states = ref(state.toStrings());
  var searchCounts = ref(state.context.results);
  var initialCounts = ref(state.context.results);
  service.onTransition(searchServiceTransitionHandler).start(state);

  // this is the transition handler, you can't/shouldn't use async/await
  function searchServiceTransitionHandler(newState, event) {
    // if (process.env.NODE_ENV !== 'production')
    //   console.log(
    //     'searchStore.onTransition',
    //     requestID,
    //     route.fullPath,
    //     newState.toStrings(),
    //     event.type,
    //   )
    if (process.server) {
      stateStr.value = JSON.stringify(newState);
    }
    if (!isEqual(searchFilters.value, newState.context.filters)) {
      searchFilters.value = newState.context.filters;
    }
    if (!isEqual(state, newState)) {
      state = newState;
    }
    if (!isEqual(state, newState.toStrings())) {
      states.value = newState.toStrings();
    }
    if (!isEqual(context.value, newState.context)) {
      context.value = newState.context;
    }
    searchCounts.value = newState.context.results;
    // initialCounts is used to determine what initial facets get disabled
    initialCounts.value = newState.context.results;
  }

  // does a search (and a search counts if dated search)
  function search(_x) {
    return _search.apply(this, arguments);
  } // the quick search function
  // called s to make it short
  // does a search with the current route
  function _search() {
    _search = _asyncToGenerator(function* (filters) {
      var _filters$dates, _route$name, _route$name2;
      var force = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var searchUrl = arguments.length > 2 ? arguments[2] : undefined;
      if (!force && isEqual(filters, searchFilters.value)) {
        return false;
      }
      if (!filters) {
        return false;
      }
      if (!searchUrl) {
        searchUrl = route.fullPath;
      }
      abort();
      isDated.value = Boolean((_filters$dates = filters.dates) === null || _filters$dates === void 0 ? void 0 : _filters$dates.arrive);
      searchFilters.value = filters;
      engine.value = (_route$name = route.name) !== null && _route$name !== void 0 && _route$name.includes('searchold') ? 1 : (_route$name2 = route.name) !== null && _route$name2 !== void 0 && _route$name2.includes('searchnew') ? 2 : 0;
      searchEngine.search(filters, searchUrl);
      yield waitForInterpreterState({
        machine: service,
        successStates: ['results', 'redirecting'],
        errorStates: ['error']
      });
      return true;
    });
    return _search.apply(this, arguments);
  }
  function s() {
    return _s.apply(this, arguments);
  } // this will just update the counts and not the search
  function _s() {
    _s = _asyncToGenerator(function* () {
      if (nuxtApp.ssrContext && isSearchUrlRoute(route, [], excludeBase)) {
        var pathCheck = checkPath(route);
        if (pathCheck.redirect) {
          if (pathCheck.url) {
            doRedirect(pathCheck.url, pathCheck.status, nuxtApp.ssrContext.res);
          } else {
            nuxtApp.ssrContext.res.statusCode = pathCheck.status || 404;
            throw createError({
              statusCode: pathCheck.status || 404,
              fatal: true
            });
          }
          return;
        }
      }
      var filters = routeToFilters(route);
      // dated price search does not have counts
      // so run a parallel search for counts
      var [_searchResult, _countsResult] = yield Promise.all([search(filters, true, route.fullPath), isDated.value ? countsSearch(filters) : Promise.resolve(true)]);
      if (nuxtApp.ssrContext) {
        var _context$value$result3, _context$value$result4;
        var wasRedirected = handleRedirects(route, nuxtApp.ssrContext.res);
        if (wasRedirected) {
          return;
        }
        try {
          setSearchXkeyHeaders(context.value, nuxtApp.ssrContext.res);
        } catch (error) {
          console.error('ERROR: setSearchXkeyHeaders', error, route.fullPath, requestID);
        }
        try {
          setLinkHeader(context.value, nuxtApp.ssrContext.res);
        } catch (error) {
          console.error('ERROR: setLinkHeader', error, requestID, route.fullPath);
        }
        if (!((_context$value$result3 = context.value.results) !== null && _context$value$result3 !== void 0 && (_context$value$result4 = _context$value$result3.campsites) !== null && _context$value$result4 !== void 0 && _context$value$result4.length)) {
          // set response to be 404
          nuxtApp.ssrContext.res.statusCode = 404;
        }
      }
    });
    return _s.apply(this, arguments);
  }
  function countsSearch(_x2) {
    return _countsSearch.apply(this, arguments);
  }
  function _countsSearch() {
    _countsSearch = _asyncToGenerator(function* (filters) {
      var _filters$dates2;
      filters = filterCountsFilter(filters);
      if (isEqual(countSearchFilters.value, filters) && searchCounts.value) {
        return true;
      }
      abortCounts();
      countSearchFilters.value = filters;
      var innerIsDated = Boolean((_filters$dates2 = filters.dates) === null || _filters$dates2 === void 0 ? void 0 : _filters$dates2.arrive);
      var fetchCounts = innerIsDated ? (langCode, filters, options) => countsService.fetchPriceSearchCounts(langCode, filters, options) : (langCode, filters, options) => countsService.fetchNonPriceSearchCounts(langCode, filters, options);
      try {
        var searchCountsResults = yield fetchCounts(langCode, filters, _objectSpread(_objectSpread({}, fetchOptions), {}, {
          signal: countsAbortController.signal
        }));
        if (searchCountsResults) {
          searchCounts.value = searchCountsResults;
        }
      } catch (error) {
        console.warn('Error fetching counts:', error, requestID);
      }
      return true;
    });
    return _countsSearch.apply(this, arguments);
  }
  function abort() {
    abortController.abort();
    abortController = new AbortController();
    abortCounts();
  }
  function abortCounts() {
    countsAbortController.abort();
    countsAbortController = new AbortController();
  }
  function handleRouteChange(_x3) {
    return _handleRouteChange.apply(this, arguments);
  }
  function _handleRouteChange() {
    _handleRouteChange = _asyncToGenerator(function* (to) {
      var isSearch = isSearchUrlRoute(to, [], excludeBase);
      if (!isSearch) {
        abort();
        return;
      }
      var filters = routeToFilters(to);
      if (!isEqual(filters, searchFilters.value)) {
        if (isDesktop) {
          // start a search but don't block nav on desktop
          void search(filters, false, to.fullPath);
        } else {
          // mobile block the nav until ready, show progress bar
          window.$nuxt.$loading.start();
          try {
            yield search(filters, false, to.fullPath);
          } catch (e) {
            console.warn('SearchError', e, requestID);
          } finally {
            window.$nuxt.$loading.finish();
          }
        }
      }
    });
    return _handleRouteChange.apply(this, arguments);
  }
  if (process.client) {
    // Register the navigation guard when the store is used
    router.beforeEach((to, from, next) => {
      // if (isDev)
      //   console.log('searchStore.beforeEach', to.fullPath, from.fullPath)
      void handleRouteChange(to).finally(next);
    });
    router.afterEach((to, from) => {
      // if (isDev)
      //   console.log('searchStore.afterEach', to.fullPath, from.fullPath)
      var isSearch = isSearchUrlRoute(to, ['searchedit', 'searcheditmaps'], excludeBase);
      if (!isSearch) {
        service.send('CLEAR_RESULTS');
      }
    });
  }
  function handleRedirects(serverRoute, res) {
    if (areHierarchiesDifferent(context.value, searchFilters.value)) {
      var _searchFilters$value;
      var url = serverRoute.fullPath.replace((_searchFilters$value = searchFilters.value) === null || _searchFilters$value === void 0 ? void 0 : _searchFilters$value.hierarchyPath, context.value.results.hierarchy);
      logger('handleRedirects1', serverRoute.fullPath, url);
      try {
        doRedirect(url, 301, res);
      } catch (error) {
        console.error('ERROR: doRedirect', error, requestID, serverRoute.fullPath);
      }
      return true;
    }
    if (states.value.includes('redirecting')) {
      try {
        var _context$value$result;
        logger('handleRedirectsSeo', (_context$value$result = context.value.results) === null || _context$value$result === void 0 ? void 0 : _context$value$result.seoRedirect, serverRoute.fullPath);
        redirectOnMachineRedirecting(context.value, res, serverRoute.fullPath);
      } catch (error) {
        console.error('ERROR: redirectOnMachineRedirecting', error, requestID, serverRoute.fullPath);
      }
      return true;
    }
    return false;
  }
  function filterCountsFilter(filters) {
    // if (isDev) console.log('filterCountsFilter', requestID, filters)
    return _objectSpread(_objectSpread({}, filters), {}, {
      page: undefined,
      limit: undefined,
      base: undefined,
      sort: undefined,
      cleared: undefined
    });
  }

  // async function waitForResults() {
  //   await waitForInterpreterState({
  //     machine: service,
  //     successStates: ['results', 'redirecting'],
  //     errorStates: ['error'],
  //   }).catch((error) => {
  //     console.error('SearchError waitForREsults', requestID, error)
  //   })
  // }

  var localizedWithin = computed(() => {
    var _context$value$result2;
    var distanceWithin = (_context$value$result2 = context.value.results) === null || _context$value$result2 === void 0 ? void 0 : _context$value$result2.within;
    return distanceWithin ? distanceWithin[distanceUnit] : '';
  });
  function checkPath(route) {
    var _filters$facets;
    var {
      facetsBySlug
    } = useFacetsStore();
    var filters = routeToFilters(route);
    var path = route.path;
    var sortParam = route.query.sort;
    if (/-\/.*%2F/.test(route.path)) {
      logger('Invalid URL pattern detected', route.fullPath);
      return {
        redirect: true,
        status: 404
      };
    }
    if (path.endsWith('/-/')) {
      var url = route.fullPath.replace(/-\//, '');
      logger('SearchMain redirect empty slash', url);
      return {
        redirect: true,
        status: 301,
        url
      };
    }
    // Check for multiple '-/' in the URL
    if ((route.fullPath.match(/-\//g) || []).length > 1) {
      logger('Multiple empty slashes found', route.fullPath);
      return {
        redirect: true,
        status: 404
      };
    }
    // Validate the 'sort' query parameter
    var validSortOptions = ['-weighted_rating', 'weighted_rating', 'price', '-price', 'rating', '-rating', 'distance', 'magic', 'best', '-best'];
    if (sortParam && !validSortOptions.includes(sortParam)) {
      logger('Invalid sort parameter', sortParam);
      return {
        redirect: true,
        status: 404
      };
    }
    // Validate facet slugs
    var invalidFacets = ((_filters$facets = filters.facets) === null || _filters$facets === void 0 ? void 0 : _filters$facets.filter(slug => !facetsBySlug(slug))) || [];
    if (invalidFacets.length) {
      return {
        redirect: true,
        status: 404
      };
    }
    if (route.path.startsWith('/404/')) {
      logger('Redirecting to 404 for path', path);
      return {
        redirect: true,
        status: 404
      };
    }
    return {
      redirect: false
    };
  }
  return {
    context,
    search,
    countsSearch,
    send: service.send,
    states,
    searchCounts,
    initialCounts,
    isDated,
    searchFilters,
    countSearchFilters,
    stateStr,
    filterCountsFilter,
    // waitForResults,
    handleRouteChange,
    reqId,
    localizedWithin,
    s,
    abort
  };
});