export interface LatLng {
  lat: number
  lng: number
}

export interface GeoPoint extends LatLng {
  isEmptyPoint: boolean
}

export interface GeoCircle extends GeoPoint {
  rangeMiles?: number
}

export function getGeoCircleFromPointAndRange(
  point: GeoPoint,
  rangeMiles: number,
): GeoCircle {
  if (!isValidRange(rangeMiles)) {
    throw new TypeError('Invalid range for GeoCircle')
  }
  const { isEmptyPoint, lat, lng } = point

  return {
    lat: isEmptyPoint ? 0 : lat,
    lng: isEmptyPoint ? 0 : lng,
    rangeMiles,
    isEmptyPoint,
  }
}

export function getWKTPointFromGeoPoint(
  point: GeoPoint | GeoCircle,
  srid?: string | number,
): string {
  const { isEmptyPoint, lat, lng } = point

  if (isEmptyPoint) {
    throw new TypeError('Cannot get WKTPoint of empty GeoPoint')
  }
  if (srid) {
    return `SRID=${srid};POINT(${lng} ${lat})`
  } else {
    return `POINT(${lng} ${lat})`
  }
}

export function isValidRange(rangeMiles: number): boolean {
  return !isNaN(rangeMiles) && rangeMiles > 0 && rangeMiles !== Infinity
}

export function isValidLatLng({ lat, lng }: LatLng) {
  return !isNaN(lat) && !isNaN(lng)
}

export function getGeoPointFromWKTPoint(wktPoint: string): GeoPoint {
  const match = wktPoint.match(/(srid=\S+;)?\s?point\s?\((\S+) (\S+)\)/i)

  if (match === null) {
    throw new TypeError(`Invalid WKT point: '${wktPoint}'`)
  }

  const lng = Number(match[2])
  const lat = Number(match[3])
  return { lat, lng, isEmptyPoint: false }
}
