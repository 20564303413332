import { defineNuxtPlugin } from '#app'

import {
  puLinksContainer,
  RouterClientImpl,
} from '~~/apps/pu-links/inversify.config'

export default defineNuxtPlugin(async (nuxtApp) => {
  const routerClient = puLinksContainer.get(RouterClientImpl)
  nuxtApp.provide('routerClient', routerClient)
  nuxtApp.provide('searchUrlState', routerClient.getPresenter())
})
