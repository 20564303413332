import { Plugin } from '@nuxt/types'

const polyfill: Plugin = async (context) => {
  if (process.server) {
    return
  }
  const code = context.app.i18n.localeInfo.code.split('-')[0]

  polyfillRequestIdleCallback()
  await Promise.all([
    polyfillIntlLocale(),
    polyfillIntlPluralRules(code),
    polyfillIntlNumberFormat(code),
  ])
}
export default polyfill

function polyfillRequestIdleCallback() {
  if (!window.requestIdleCallback) {
    console.log('polyfilling requestIdleCallback')
    window.requestIdleCallback = requestIdleCallbackPolyfill
    window.cancelIdleCallback = cancelIdleCallbackPolyfill
  }
}

async function polyfillIntlPluralRules(code: string) {
  if (!detectPluralRules()) {
    console.log('polyfilling intl plural rules')
    await import(
      /* webpackChunkName: "formatjs-intl-pluralrules", webpackMode: "lazy" */ '@formatjs/intl-pluralrules/polyfill'
    )
    console.log('polyfilling intl plural rules locale data', code)
    await import(
      /* webpackChunkName: "formatjs-intl-pluralrules-locale-[index]", webpackMode: "lazy", webpackInclude: /(en|en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)\.js/ */ `@formatjs/intl-pluralrules/locale-data/${code}`
    )
  }
}

async function polyfillIntlLocale() {
  if (!detectInlLocale()) {
    console.log('polyfilling intl locale')
    await import(
      /* webpackChunkName: "formatjs-intl-locale", webpackMode: "lazy" */ '@formatjs/intl-locale/polyfill'
    )
  }
}

async function polyfillIntlNumberFormat(code: string) {
  if (!detectIntlNumberFormat()) {
    console.log('polyfilling intl number format')
    await import(
      /* webpackChunkName: "formatjs-intl-numberformat", webpackMode: "lazy" */ '@formatjs/intl-numberformat/polyfill'
    )
    console.log('polyfilling intl number format locale', code)
    await import(
      /* webpackChunkName: "formatjs-intl-numberformat-locale-[index]", webpackMode: "lazy", webpackInclude: /(en|en-gb|en-us|en-au|en-ca|en-nz|en-za|en-ie|fr|fr-ca|fr-be|pt-br|pt-pt|zh-hans|nl|pl|ca|cs|da|de|es|el|hu|it|no|ru|sv)\.js/ */ `@formatjs/intl-numberformat/locale-data/${code}`
    )
  }
}

function detectInlLocale() {
  return 'Intl' in self && 'Locale' in self.Intl
}

function detectIntlNumberFormat() {
  return (
    'Intl' in self &&
    'NumberFormat' in self.Intl &&
    (() => {
      try {
        /* tslint:disable */
        new Intl.NumberFormat(undefined, {
          style: 'unit',
          unit: 'byte',
        })
        /* tslint:enable */
      } catch (e) {
        return false
      }
      return true
    })()
  )
}

function requestIdleCallbackPolyfill(cb: any): any {
  const start = Date.now()
  return setTimeout(() => {
    cb({
      didTimeout: false,
      timeRemaining() {
        return Math.max(0, 50 - (Date.now() - start))
      },
    })
  }, 1)
}

function cancelIdleCallbackPolyfill(id: any) {
  clearTimeout(id)
}

function detectPluralRules() {
  return 'Intl' in self && 'PluralRules' in self.Intl
}
