import { GeoPoint } from './GeoLocation'

export class Distance {
  mi: number
  km: number

  static new(params: Partial<Distance>): Distance {
    if (params.km !== undefined) {
      return distanceFromKm(params.km)
    } else if (params.mi !== undefined) {
      return distanceFromMi(params.mi)
    } else throw new Error('You need to specify either miles or kilometers')
  }

  static between(p1: GeoPoint, p2: GeoPoint): Distance {
    const lat1 = p1.lat
    const lat2 = p2.lat
    const lon1 = p1.lng
    const lon2 = p2.lng

    // source: https://www.movable-type.co.uk/scripts/latlong.html
    const lat1Radians = (lat1 * Math.PI) / 180 // φ, λ in radians
    const flat2Radians = (lat2 * Math.PI) / 180
    const latitudeDeltaRadians = ((lat2 - lat1) * Math.PI) / 180
    const longitudeDelta = ((lon2 - lon1) * Math.PI) / 180

    const a =
      Math.sin(latitudeDeltaRadians / 2) * Math.sin(latitudeDeltaRadians / 2) +
      Math.cos(lat1Radians) *
        Math.cos(flat2Radians) *
        Math.sin(longitudeDelta / 2) *
        Math.sin(longitudeDelta / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    const earthRadiusKilometers = 6371
    const km = earthRadiusKilometers * c

    return Distance.new({ km })
  }
}

function distanceFromKm(km: number): Distance {
  return {
    km,
    mi: km / 1.609344,
  }
}

function distanceFromMi(mi: number): Distance {
  return {
    km: mi * 1.609344,
    mi,
  }
}

export function convertMetersToDistanceObject(distanceInMeters: string) {
  const km = parseInt(distanceInMeters.replace(' m', ''), 0) / 1000
  return Distance.new({ km })
}
