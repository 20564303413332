import { Component, Mixins } from '~/utility/pu-class-decorator'
import AuthMixin from './AuthMixin'

@Component
export default class RequireAuthMixin extends Mixins(AuthMixin) {
  async authMounted() {
    if (!this.isLoggedIn) {
      // tslint:disable no-floating-promises
      this.$router.push(this.redirectUrl)
    }
  }

  get redirectUrl() {
    return this.$puLocalePath({
      name: 'accounts-login',
      query: {
        next: this.$route.fullPath,
      },
    })
  }
}
