import { Context } from '@nuxt/types'
import Vue from 'vue'

export default function (_: Context) {
  Vue.directive('observe-visibility', {
    bind(el: HTMLElement, binding: any, vnode: any, oldVnode: any) {
      requestIdleCallback(() => {
        import(
          /* webpackChunkName: "vue-observe-visibility", webpackMode: "lazy" */ 'vue-observe-visibility'
        )
          .then((vo) => {
            Vue.use(vo.default)
            vo.ObserveVisibility.bind &&
              vo.ObserveVisibility.bind(el, binding, vnode, oldVnode)
          })
          .catch(() => {
            // Error ignored
          })
      })
    },
  })
}
