import { defineStore } from 'pinia';
import { assign, createMachine, interpret } from 'xstate';

// Define the counter machine directly inside the store file
var counterMachine = createMachine({
  id: 'counter',
  initial: 'active',
  context: {
    count: 0
  },
  states: {
    active: {
      on: {
        INCREMENT: {
          actions: 'incrementCount'
        },
        DECREMENT: {
          actions: 'decrementCount'
        },
        RESET: {
          actions: 'resetCount'
        }
      }
    }
  }
}, {
  actions: {
    incrementCount: assign({
      count: context => context.count + 1
    }),
    decrementCount: assign({
      count: context => context.count - 1
    }),
    resetCount: assign({
      count: () => 0
    })
  }
});
export var useCounterStore = defineStore(counterMachine.id, () => {
  var service = interpret(counterMachine);
  var state = ref(service.state);
  var context = ref(counterMachine.context);
  service.onTransition(newState => {
    state.value = newState;
    context.value = newState.context;
  }).start();
  function increment() {
    service.send('INCREMENT');
  }
  function decrement() {
    service.send('DECREMENT');
  }
  function reset() {
    service.send('RESET');
  }
  return {
    state,
    context,
    increment,
    decrement,
    reset
  };
});