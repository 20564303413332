import { defineNuxtPlugin } from '#app'
import Vue from 'vue'

const createSnackPromise = () => {
  if (Vue.prototype.$snack) {
    return Promise.resolve(Vue.prototype.$snack)
  }
  return Promise.all([
    import(
      /* webpackChunkName: "vue-snack", webpackMode: "lazy" */ '~/apps/snack/index.js'
    ),
    import(
      /* webpackChunkName: "vue-snack", webpackMode: "lazy" */ '~/assets/css/snackbar.css'
    ),
  ]).then(([snack]) => {
    Vue.use(snack.default)
    // hack to make snackbar work when used lazily
    document.dispatchEvent(new Event('DOMContentLoaded'))
    return Vue.prototype.$snack
  })
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('getSnackbar', () => createSnackPromise())
})
