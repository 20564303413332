import { Component, Vue } from '~/utility/pu-class-decorator'
import { AuthMachineInterpreter } from '../apps/auth/authMachine'
import { getAuthService } from '../apps/auth/service'

@Component
export default class AuthMixin extends Vue {
  authState: AuthMachineInterpreter['state'] | null = null
  authMachine: AuthMachineInterpreter

  async mounted() {
    this.authMachine = getAuthService()
    this.authState = this.authMachine.state
    this.authMachine.onTransition(this.authStateChanged)
    await this.waitForAuth(this.authState)
    this.$nextTick(async () => await this.authMounted())
  }

  private async waitForAuth(authState: AuthMachineInterpreter['state']) {
    if (authState.matches('initialising')) {
      await new Promise((resolve) => {
        this.authMachine.onTransition((state) => {
          if (!state.matches('initialising')) {
            resolve(state)
          }
        })
      })
    }
  }

  async authMounted() {
    // empty hook for base class to implement
  }

  logOut(redirectTo?: string) {
    this.authMachine.send('LOG_OUT')
    if (redirectTo) {
      this.$router.push(redirectTo)
    } else if (this.$isDesktop) {
      this.$router.push(this.$puLocalePath({ name: 'accounts-login' }))
    }
  }

  logOutInPlace() {
    this.authMachine.send('LOG_OUT')
  }

  get isLoggedIn() {
    if (process.server) {
      return false
    }
    return this.authState && this.authState.matches('loggedIn')
  }

  get isLoggingIn() {
    if (process.server) {
      return false
    }
    return this.authState && this.authState.matches('loggingIn')
  }

  get isLoggedOut() {
    if (process.server) {
      return true
    }
    return !this.isLoggedIn
  }

  get isLoggingOutSuccess() {
    if (process.server) {
      return false
    }
    return this.authState && this.authState.matches('logoutSuccess')
  }

  get isLoggingInSuccess() {
    if (process.server) {
      return false
    }
    return this.authState && this.authState.matches('loginSuccess')
  }

  get authHasErrors() {
    if (process.server) {
      return false
    }
    return this.authState && this.authState.matches('loggedOut.error')
  }

  get userEmail() {
    if (process.server) {
      return ''
    }
    return this.authState && this.authState.context.email
  }

  get isManager() {
    if (process.server) {
      return false
    }
    return this.authState?.context.isManager
  }

  private authStateChanged(state: AuthMachineInterpreter['state']) {
    if (state.changed) {
      this.authState = state
    }
  }
}
