/* eslint-disable no-console */
import { useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import Vue from 'vue'

interface FormData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

const isDev = process.env.NODE_ENV !== 'production'

export const useFormStore = defineStore('form', () => {
  const formData = useStorage<FormData>('form-data', {})
  if (isDev) {
    console.log('useFormStore.formData', formData)
  }

  function updateField(fieldName: string, value) {
    if (isDev) {
      console.log('updateField', fieldName, value, formData)
    }
    // needed for reactivity
    Vue.set(formData.value, fieldName, value)
  }

  return { formData, updateField }
})
