function toIsoDate(date: Date): string {
  const yyyy = date.getFullYear()
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const dd = String(date.getDate()).padStart(2, '0')
  return `${yyyy}-${mm}-${dd}`
}

function fromIsoDate(isoDate: string) {
  return new Date(isoDate)
}

function isIsoDate(date: string) {
  const regex = /\d\d\d\d-((?!00)0[0-9]|1[0-2])-((?!00)[0-2][0-9]|3[0-1])/
  return regex.test(date)
}

// tslint:disable-next-line variable-name
export const IsoDateFormatter = { toIsoDate, fromIsoDate, isIsoDate }
