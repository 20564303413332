/* eslint-disable no-console */
import { SYMBOLS } from '../apps/inversify/symbols'
import { rootContainer } from '~/container'

export default function sentryCapture(error, container?) {
  // when sentry hasn't bound yet this would cause errors
  // so handle it
  if (!(container || rootContainer).isBound(SYMBOLS.SentryReadyInstance)) {
    console.error(error)
    if (error.message) {
      console.error(error.message)
    }
    if (error.issues) {
      error.issues.forEach((issue) => {
        console.error(issue)
      })
    }
    return
  }
  return (container || rootContainer)
    .get(SYMBOLS.SentryReadyInstance)()
    .then((sentry) => {
      if (sentry.getCurrentHub().getClient()) {
        sentry.captureException(error)
      } else {
        console.error(error)
      }
    })
    .catch((e) => console.error(e))
}

export function sentryCaptureMessage(message, container?) {
  if (!(container || rootContainer).isBound(SYMBOLS.SentryReadyInstance)) {
    console.error(message)
    return
  }
  return (container || rootContainer)
    .get(SYMBOLS.SentryReadyInstance)()
    .then((sentry) => {
      if (sentry.getCurrentHub().getClient()) {
        sentry.captureMessage(message)
      } else {
        console.error(message)
      }
    })
    .catch((e) => console.error(e))
}
