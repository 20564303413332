export function ensureArray<T>(data: T | T[] | undefined | null): T[] {
  if (data === null || data === undefined) {
    return []
  }
  return Array.isArray(data) ? data : [data]
}

// return a list of objects keyed by a field (usually id)
// for unique only keys
export function arrayByKey<T>(array: T[], field: keyof T): Record<any, T> {
  return Object.assign(
    {},
    ...array.map((instance) => ({ [instance[field] as any]: instance })),
  )
}
