type EventHandler<T = any> = (data: T) => void

export class EventEmitter<Events extends Record<string, any>> {
  private events = new Map<keyof Events, Set<EventHandler>>()

  on<E extends keyof Events>(
    event: E,
    handler: EventHandler<Events[E]>,
  ): () => void {
    this.addListener(event, handler)
    return () => this.removeListener(event, handler)
  }

  private addListener<E extends keyof Events>(
    event: E,
    handler: EventHandler<Events[E]>,
  ) {
    const handlers = this.events.get(event)
    if (handlers) {
      handlers.add(handler)
    } else {
      this.events.set(event, new Set<EventHandler>([handler]))
    }
  }

  private removeListener<E extends keyof Events>(
    event: E,
    handler: EventHandler<Events[E]>,
  ) {
    const handlers = this.events.get(event)
    if (handlers) {
      handlers.delete(handler)
    }
  }

  emit<E extends keyof Events>(event: E, payload?: Events[E]) {
    const handlers = this.events.get(event)
    if (handlers) {
      for (const handler of handlers) {
        handler(payload)
      }
    }
  }
}
