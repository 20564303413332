import { addDays, daysBetween } from './relative'

export class DateRange {
  readonly totalNights: number
  readonly start: string
  readonly end: string

  constructor(start: string, end: string | number) {
    this.start = start

    if (typeof end === 'number') {
      this.totalNights = end
      this.end = addDays(end, this.start)
    } else {
      this.end = end
      this.totalNights = daysBetween(start, end)
    }
  }

  get days(): number {
    return this.totalNights + 1
  }

  get nights(): number {
    return this.totalNights
  }

  addDays(days: number): DateRange {
    return new DateRange(addDays(days, this.start), addDays(days, this.end))
  }
}
