import Vue from 'vue'

export function vueReactiveAssign<T extends Record<string, any>>(
  baseObject: T,
  newProperties: T,
): T {
  Object.keys(newProperties).forEach(key =>
    Vue.set(baseObject, key, newProperties[key]),
  )
  return baseObject
}
