export default function isNotAnonAxiosError(err) {
  if (!('isAxiosError' in err) && !err.isAxiosError) {
    return true
  }

  if (('response' in err && err.response) || ('code' in err && err.code)) {
    return true
  } else {
    return false
  }
}
