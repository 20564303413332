var puLocalePath = (_ref, inject) => {
  var {
    app
  } = _ref;
  inject('puLocalePath', (route, locale) => {
    var currentUrl = app.localePath(route, locale);
    var targetLocale = locale || app.i18n.locale;
    return getUrl({
      currentUrl,
      targetLocale,
      defaultLocale: app.i18n.defaultLocale
    });
  });
};
function getUrl(_ref2) {
  var {
    currentUrl,
    targetLocale,
    defaultLocale
  } = _ref2;
  var targetLocalePart = targetLocale === defaultLocale ? undefined : "/".concat(targetLocale, "/");
  if (currentUrl.startsWith('/') && targetLocalePart && !currentUrl.startsWith(targetLocalePart)) {
    return generateLocalisedUrl(currentUrl, targetLocale);
  }
  return currentUrl;
}
function generateLocalisedUrl(href, locale) {
  var urlWithNoLeadingSlash = href.replace(/^\/+/g, '');
  return "/".concat(locale, "/").concat(urlWithNoLeadingSlash);
}
export default puLocalePath;