function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineStore } from 'pinia';
import { isLocalStorageAvailable } from '~/utility/isLocalStorageAvailable';
export var useOnboardingStore = defineStore('onboardingStore', () => {
  var storageKey = 'onboardingStates';
  var onboardingStates = ref({});
  function getOnboardingStates() {
    if (!isLocalStorageAvailable) {
      return {};
    }
    var storageObj = JSON.parse(localStorage.getItem(storageKey) || '{}');
    return storageObj;
  }
  function setOnboardingState(key, value) {
    if (!isLocalStorageAvailable) {
      return;
    }
    onboardingStates.value = _objectSpread(_objectSpread({}, onboardingStates.value), {}, {
      [key]: value
    });
    localStorage.setItem(storageKey, JSON.stringify(onboardingStates.value));
  }
  function setFavouritesOnboardingState(_ref) {
    var {
      shown,
      closed
    } = _ref;
    if (shown !== undefined) {
      setOnboardingState('favourites', shown);
    }
    if (closed !== undefined) {
      setOnboardingState('favouritesClosed', closed);
    }
  }
  var shouldShowFavouritesOnboarding = computed(() => {
    return 'favouritesClosed' in onboardingStates.value && !onboardingStates.value.favouritesClosed && ('favourites' in onboardingStates.value ? !onboardingStates.value.favourites : true);
  });
  var handleStorageEvent = event => {
    if (event.key === storageKey) {
      onboardingStates.value = getOnboardingStates();
    }
  };
  onMounted(() => {
    onboardingStates.value = getOnboardingStates();

    // Sync changes across browser tabs
    window.addEventListener('storage', handleStorageEvent);
  });
  onUnmounted(() => {
    window.removeEventListener('storage', handleStorageEvent);
  });
  return {
    setFavouritesOnboardingState,
    shouldShowFavouritesOnboarding
  };
});