import { ensureArray } from './array'

export const toCamel = (s: string) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

function isObject(o: any) {
  return o === Object(o) && !Array.isArray(o) && typeof o !== 'function'
}

export function keysToCamel(o: any, skipKeys?: string[]) {
  if (isObject(o)) {
    const n = {}
    Object.keys(o).forEach((k) =>
      ensureArray(skipKeys).includes(k)
        ? (n[toCamel(k)] = o[k])
        : (n[toCamel(k)] = keysToCamel(o[k], skipKeys)),
    )
    return n
  } else if (Array.isArray(o)) {
    return o.map((i) => keysToCamel(i, skipKeys))
  }
  return o
}
