import { NumberParser } from '~~/utility/i18n/number-parser'
import { extend, ValidationProvider } from 'vee-validate'
import {
  email,
  max,
  min,
  numeric,
  double,
  max_value,
  min_value,
  regex,
  required,
} from 'vee-validate/dist/rules'
import { Component, Vue } from '~/utility/pu-class-decorator'

extend('required', {
  ...required,
})
extend('firstNameRequired', {
  ...required,
})
extend('emailRequired', {
  ...required,
  ...email,
})
extend('max', {
  ...max,
})
extend('min', {
  ...min,
})
extend('email', {
  ...email,
})
extend('numeric', {
  ...numeric,
})
extend('double', {
  ...double,
  castValue: (value) => Number(new NumberParser().parse(value)),
})
extend('max_value', {
  ...max_value,
  params: [
    {
      name: 'max',
      cast(value) {
        return Number(new NumberParser().parse(value))
      },
    },
  ],
  castValue: (value) => Number(new NumberParser().parse(value)),
})
extend('min_value', {
  ...min_value,
})
extend('regex', {
  ...regex,
})
extend('dialingCodeRequired', (value) => {
  if (value !== ' ') return true
  return 'Please tell us your telephone number'
})

@Component({
  components: {
    'validation-provider': ValidationProvider,
  },
})
export default class ValidationProviderMixin extends Vue {}
