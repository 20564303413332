/**
 * This Vue directive, 'format-number', is used to format the input numbers in a text field.
 * It listens to the 'input' event on the element and formats the input number to a specified number of decimal places.
 * The number of decimal places is passed as a binding value to the directive.
 *
 * The directive also takes into account the locale of the user to format the number accordingly.
 * It uses the 'NumberParser' class to parse the input number based on the locale.
 *
 * The directive also updates the 'v-model' of the element with the formatted number.
 *
 * Usage:
 * In your Vue template, bind the directive to an element like this:
 * <input v-format-number="'2'" v-model="myNumber" />
 * This will format the input number to 2 decimal places.
 *
 * You can replace '2' with any number to round to that many decimal places.
 */
import { Context } from '@nuxt/types'
import Vue from 'vue'
import { getLocaleInfo } from '~~/lang/locales'
import { NumberParser } from '~~/utility/i18n/number-parser'

export default function ({ route }: Context) {
  Vue.directive('format-number', {
    bind(el, binding, vnode) {
      function parseNumber(e) {
        const target = e.target as HTMLInputElement
        const decimalPlaces = parseInt(binding.value)
        const localeInfo = getLocaleInfo(route.params.lang)
        const number = new NumberParser(localeInfo.iso).parse(target.value)
        // Get the number of decimal places from the binding value
        // Round the number to the specified decimal places
        const roundedNumber =
          countDecimalPlaces(number.toString()) > decimalPlaces
            ? number.toFixed(decimalPlaces)
            : number.toString()
        const newValue = Number(roundedNumber).toLocaleString(localeInfo.iso, {
          useGrouping: false,
        })
        if (newValue === NaN.toString()) {
          return
        }
        target.value = newValue
        // @ts-ignore
        const dataName = vnode.data.model.expression
        if (dataName && vnode && vnode.context) {
          vnode.context[dataName] = newValue
        }
      }
      el.querySelector('input')?.addEventListener('blur', parseNumber)
    },
  })
}

function countDecimalPlaces(value: string) {
  const match = value.match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/)
  if (!match) {
    return 0
  }
  return Math.max(
    0,
    (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0),
  )
}
