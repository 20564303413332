// tslint:disable no-duplicate-string
const mapTransform = {
  small: 'format=auto,fit=cover,quality=50,w=90,h=90',
  medium: 'format=auto,fit=cover,quality=30,w=120,h=120',
  large: 'format=auto,fit=cover,quality=30,w=180,h=180',
  masterImage: '',
}

export const TRANSFORMATIONS = {
  bookingCampsite: {
    masterImage: '',
  },
  campsitePrimaryPhotoMaster: {
    masterImage: '',
  },
  favourite: {
    gallerySmall: 'format=auto,fit=cover,quality=80,w=360,h=270',
    galleryMedium: 'format=auto,fit=cover,quality=30,w=720,h=540',
    galleryLarge: 'format=auto,fit=cover,quality=30,w=1080,h=810',
    mapLarge: mapTransform.large,
    mapMedium: mapTransform.medium,
    mapSmall: mapTransform.small,
    masterImage: '',
  },
  gallery: {
    masterImage: '',
  },
  map: { ...mapTransform },
  nationalParks: {
    photo: 'format=auto,fit=cover,quality=30,w=160,h=160',
  },
  nearbySite: {
    small: 'format=auto,fit=cover,quality=30,w=300,h=225',
    large: 'format=auto,fit=cover,quality=30,w=480,h=360',
    masterImage: '',
  },
  pitchType: {
    detailSmall: 'format=auto,fit=cover,quality=50,w=132,h=99',
    detailMedium: 'format=auto,fit=cover,quality=30,w=176,h=132',
    detailLarge: 'format=auto,fit=cover,quality=30,w=264,h=198',
    gallerySmall: 'format=auto,fit=cover,quality=80,w=360,h=270',
    galleryMedium: 'format=auto,fit=cover,quality=30,w=720,h=540',
    galleryLarge: 'format=auto,fit=cover,quality=30,w=1080,h=810',
    summarySmall: 'format=auto,fit=cover,quality=50,w=90,h=90',
    summaryMedium: 'format=auto,fit=cover,quality=30,w=120,h=120',
    summaryLarge: 'format=auto,fit=cover,quality=30,w=180,h=180',
    masterImage: '',
  },
  recentCampsite: {
    photo: 'format=auto,fit=cover,quality=30,w=176,h=132',
    masterImage: '',
  },
  review: {
    detailSmall: 'format=auto,fit=cover,quality=50,w=132,h=99',
    detailMedium: 'format=auto,fit=cover,quality=30,w=176,h=132',
    detailLarge: 'format=auto,fit=cover,quality=30,w=264,h=198',
    gallerySmall: 'format=auto,fit=cover,quality=80,w=360,h=270',
    galleryMedium: 'format=auto,fit=cover,quality=30,w=720,h=540',
    galleryLarge: 'format=auto,fit=cover,quality=30,w=1080,h=810',
    masterImage: '',
  },
  topDestinations: {
    image: 'format=auto,fit=cover,quality=30,w=720,h=540',
    masterImage: '',
  },
  userReview: {
    small: 'format=auto,fit=cover,quality=50,w=132,h=99',
    medium: 'format=auto,fit=cover,quality=30,w=176,h=132',
    large: 'format=auto,fit=cover,quality=30,w=264,h=198',
    masterImage: '',
  },
  userReviewCampsite: {
    small: 'format=auto,fit=cover,quality=50,w=90,h=90',
    medium: 'format=auto,fit=cover,quality=30,w=120,h=120',
    large: 'format=auto,fit=cover,quality=30,w=180,h=180',
    masterImage: '',
  },
}
