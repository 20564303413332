import { v4 as uuidv4 } from 'uuid';
import { getCookie, setCookie } from '~/utility/cookies';
var USER_SESSION_COOKIE = 'user_session_id';
var userTracking = (ctx, inject) => {
  var sessionId = getCookie(USER_SESSION_COOKIE);
  if (!sessionId) {
    sessionId = uuidv4();
  }
  // Set cookie to expire in 24 hours
  setCookie(USER_SESSION_COOKIE, sessionId, 1);

  // Inject the sessionId into the context, so it's available everywhere
  inject('sessionId', sessionId);
};
export default userTracking;