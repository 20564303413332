import { GeoPoint, getGeoPointFromWKTPoint } from './GeoLocation'

export function latLng(point: string | GeoPoint): { lat: number; lng: number } {
  return typeof point === 'string'
    ? parseWKXGeometryPoint(point)
    : {
        lat: point.lat,
        lng: point.lng,
      }
}

function parseWKXGeometryPoint(point: string) {
  const { lat, lng } = getGeoPointFromWKTPoint(point)
  return {
    lat,
    lng,
  }
}

export function getPointFromStringOrDefault(
  pointStr?: string | { lat: number; lng: number },
): GeoPoint {
  const point = getPointFromString(pointStr)
  return point ? point : { lat: 0, lng: 0, isEmptyPoint: false }
}

export function getPointFromString(
  point?: string | { lat: number; lng: number },
): GeoPoint | undefined {
  if (point) {
    if (typeof point === 'string') {
      return getGeoPointFromWKTPoint(point)
    } else {
      const { lat, lng } = point
      return { lat, lng, isEmptyPoint: false }
    }
  }
  return
}

export function getWKTPoint(lat: number, lng: number): string {
  return `POINT(${lng} ${lat})`
}
