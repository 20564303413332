import { Plugin } from '@nuxt/types'
import { update } from '../utility/reload'

const UA_KEY = '_ua_change'
let currentUA = ''

const plugin: Plugin = (context) => {
  const intervalId: ReturnType<typeof setInterval> | null = null

  const checkUserAgent = (to?: Route) => {
    if (process.env.NODE_ENV !== 'production')
      console.log('checkUserAgent', currentUA, navigator.userAgent)
    try {
      const ua = currentUA
      const hasUaChanged =
        ua && navigator.userAgent && ua !== navigator.userAgent
      currentUA = navigator.userAgent
      if (hasUaChanged) {
        if (intervalId) clearInterval(intervalId)
        update(to).catch(() => {
          /* ignore */
        })
      }
    } catch (e) {
      // console.error(e)
    }
  }
  context.app.router.beforeEach((to, from, next) => {
    checkUserAgent(to)
    next()
  })
  checkUserAgent()
  // if (typeof navigator !== 'undefined') {
  //   intervalId = setInterval(checkUserAgent, 1000) // Use setInterval instead of recursive setTimeout
  // }

  // // Cleanup mechanism to clear the interval when the window is unloaded
  // window.addEventListener('unload', () => {
  //   if (intervalId) clearInterval(intervalId)
  // })
}
export default plugin

