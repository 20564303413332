export {
  Component,
  Inject,
  Mixins,
  Model,
  Prop,
  Provide,
  Ref,
  Vue,
  Watch,
} from 'vue-property-decorator'
export const toNative = function <T>(obj: T): T {
  return obj
}
