export function pathSanitizer(config: PathSanitizerConfig) {
  return (path: string | undefined | null) => {
    const pathNotNull = typeof path === 'string' ? path : ''
    const midPath = sanitizeMidPath(pathNotNull)

    const terminatedPath = `${config.prependSlash ? '/' : ''}${midPath}${
      config.appendSlash ? '/' : ''
    }`
    const terminatedSafe = noDoubleSlashOnEmpty(terminatedPath)
    return ensureCasing(terminatedSafe, config)
  }
}

function sanitizeMidPath(path: string) {
  return path
    .split('/')
    .filter((subpath) => subpath.length > 0)
    .join('/')
}

function noDoubleSlashOnEmpty(path: string) {
  if (path === '//') return '/'
  else return path
}

function ensureCasing(path: string, config: PathSanitizerConfig) {
  if (config.toLowerCase) return path.toLowerCase()
  else return path
}

export interface PathSanitizerConfig {
  prependSlash: boolean
  appendSlash: boolean
  toLowerCase: boolean
}
