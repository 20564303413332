import Vue from 'vue'

interface JumpOptions {
  duration?: number
  offset?: number
  callback?: any
  easing?: any
  a11y?: boolean
}

export type ScrollTo = typeof scrollto

function scrollto(target: string | number, options: JumpOptions) {
  import(/* webpackChunkName: "jump-js", webpackMode: "lazy" */ 'jump.js')
    .then((jump) => target && jump.default(target, options))
    .catch(() => {
      /* empty */
    })
}

Vue.prototype.$puScrollTo = scrollto

/*
  simple directive wrapper for https://github.com/callmecavs/jump.js

  To jump to an element pass a CSS selector string.
  To scroll from the current position pass a pos or neg number (pixels).
  eg:
  v-scrollto="{target: '#search', options: {duration: 750, offset: -50, a11y: true} }"
  a11y: true - will add tabindex to el and focus on scrollto
  optional noscroll bool param to skip the scroll - based on some parent component object state
*/
Vue.directive('scrollto', {
  inserted: (el, binding, vnode) => {
    const handler = () => {
      if ((vnode.context as any).noscroll) {
        // don't scroll
      } else {
        scrollto(binding.value.target, binding.value.options)
      }
    }
    el.addEventListener('click', handler)
  },
})

/*
  scroll directive - trigger a handler function when window scrolls

  v-scroll="handleScroll"
  then eg:

  methods: {
    handleScroll: function (evt, el) {
      if (window.scrollY > 50) {
        el.classList.add('some-class')
      }
      return window.scrollY > 100
    }
  }
*/
Vue.directive('scroll', {
  inserted: (el, binding) => {
    const f = (evt) => {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f, { passive: true })
  },
})
