import { Plugin } from '@nuxt/types'
import {
  Metric,
  onCLS,
  onFCP,
  onFID,
  onINP,
  onLCP,
  onTTFB,
} from 'web-vitals/attribution'

const plugin: Plugin = ({ $gtm, $sentryReady }) => {
  function sendToGoogleAnalytics({ name, delta, value, id, attribution }) {
    const eventParams = {
      event: name,
      event_category: 'Web Vitals',
      // The `id` value will be unique to the current page load. When sending
      // multiple values from the same page (e.g. for CLS), Google Analytics can
      // compute a total by grouping on this ID (note: requires `eventLabel` to
      // be a dimension in your report).
      event_label: id,
      // Google Analytics metrics must be integers, so the value is rounded.
      // For CLS the value is first multiplied by 1000 for greater precision
      // (note: increase the multiplier for greater precision if needed).
      value: Math.round(name === 'CLS' ? delta * 1000 : delta),

      // OPTIONAL: any additional attribution params here.
      // See: https://web.dev/debug-performance-in-the-field/
      // dimension1: '...',
      // dimension2: '...',
      // ...

      // Custom params:
      dimension1: '',
      dimension2: value,
    }

    switch (name) {
      case 'CLS':
        eventParams.dimension1 = attribution.largestShiftTarget
        break
      case 'LCP':
        eventParams.dimension1 = attribution.element
        break
      case 'FID':
      case 'INP':
        eventParams.dimension1 = attribution.eventTarget
        break
    }

    console.log(eventParams)
    $gtm.push(eventParams)
  }

  // @ts-ignore
  onCLS(sendToGoogleAnalytics)
  // also report all CLS changes if 'reportcls' in useragent
  // if (
  //   window.navigator.userAgent &&
  //   window.navigator.userAgent.includes('reportcls')
  // ) {
  // @ts-ignore
  onCLS(
    (metric: Metric) => {
      // const valueRounded = roundToTenth(metric.value)
      // const isCLSErrorLogged =
      //   sessionStorage && sessionStorage.getItem('isCLSErrorLogged')
      // if (!isCLSErrorLogged && metric.value >= 1.1) {
      //   sessionStorage && sessionStorage.setItem('isCLSErrorLogged', 'true')
      //   if ($sentryReady) {
      //     void $sentryReady().then((Sentry) => {
      //       Sentry.captureMessage(`CLS ${metric.rating}`, {
      //         tags: {
      //           metric_rating: metric.rating,
      //           metric_value: valueRounded,
      //           metric_name: metric.name,
      //           metric_navigation_type: metric.navigationType,
      //         },
      //         // extra is deprectated but don't know how to use context yet
      //         extra: {
      //           metric,
      //         },
      //       })
      //     })
      //   } else {
      //     console.error('CLS:', metric)
      //   }
      // } else {
      console.log('CLS:', metric)
      // }
    },
    { reportAllChanges: true },
  )
  // }
  // @ts-ignore
  onFID(sendToGoogleAnalytics)
  // @ts-ignore
  onLCP(sendToGoogleAnalytics)
  // @ts-ignore
  onFCP(sendToGoogleAnalytics)
  // @ts-ignore
  onINP(sendToGoogleAnalytics)
  // @ts-ignore
  onTTFB(sendToGoogleAnalytics)
}

export default plugin

function roundToTenth(num) {
  const roundedNum = Math.round(num * 10) / 10
  return roundedNum.toFixed(1)
}
