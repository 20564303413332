import Dinero, { Currency } from 'dinero.js'

export interface SimpleMoney {
  amount?: number
  amountMinor: number
  currency: string
}

export interface MoneyRaw {
  amount: number
  currency: string
}

export type MoneyType = Dinero.Dinero

export function simpleMoneyToMoney(money: SimpleMoney): MoneyType {
  return Dinero({
    amount: money.amountMinor,
    currency: money.currency as Currency,
  })
}

export function toMoneyType(price: MoneyRaw | MoneyType): MoneyType {
  if ('amount' in price) {
    return Dinero({
      ...price,
      currency: price.currency as Currency,
      amount: Math.round(price.amount),
    })
  }
  return price
}

export function priceToMoneyType(
  price: MoneyRaw | MoneyType | SimpleMoney,
): MoneyType {
  if ('amountMinor' in price) {
    return simpleMoneyToMoney(price)
  }
  if ('amount' in price && !isNaN(price.amount)) {
    return toMoneyType(price)
  }
  if ('getAmount' in price) {
    return price
  }
  return toMoneyType({ amount: 0, currency: 'GBP' })
}

export { Dinero as Money }

export interface CurrencyRate {
  code: Currency
  rate: number
}

export function convertMoneyCurrency(
  moneyToConvert: MoneyType,
  toGBPRate: CurrencyRate,
  targetCurrency: CurrencyRate,
): MoneyType {
  const targetCurrencyRate = parseInt(
    (targetCurrency.rate * 100).toFixed(0),
    10,
  )
  const moneyConvertedToBaseCurrency =
    moneyToConvert.getAmount() / toGBPRate.rate
  const convertedMoney = parseInt(
    ((moneyConvertedToBaseCurrency * targetCurrencyRate) / 100).toFixed(0),
    10,
  )
  return Dinero({
    amount: convertedMoney,
    currency: targetCurrency.code,
  })
}
