import Vue from 'vue'

export default () => {
  Vue.use((vue) => {
    vue.prototype.$bubble = function $bubble(eventName, ...args) {
      // Emit the event on all parent components
      let component = this
      do {
        component.$emit(eventName, ...args)
        component = component.$parent
      } while (component)
    }
  })
}
