export default defineNuxtPlugin(() => {
  var route = useRoute();
  // TODO: this doesn't capture all search pages like the category ones
  // should use route.meta
  if (route.name === 'campsites-search' || route.name === 'campsite-detail') {
    requestIdleCallback(() => {
      var gallery = document.getElementById('search-gallery-0');
      requestAnimationFrame(() => {
        gallery === null || gallery === void 0 ? void 0 : gallery.setAttribute('data-gallery-position', "".concat(gallery === null || gallery === void 0 ? void 0 : gallery.scrollLeft));
      });
    });
  }
});