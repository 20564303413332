import { Route } from '~/apps/pu-links/domain/Route'

export function getMetaFromRoute(nextRoute: Route) {
  let routeMeta =
    nextRoute.meta && Array.isArray(nextRoute.meta)
      ? (nextRoute.meta || [{}]).reduce(
        (acc: any, obj: any) => Object.assign(acc, obj),
        {},
      )
      : nextRoute.meta || {}
  // for some reason meta is not always on the component
  // but can be on the matched component default options
  // so let's merge that in
  const matchedMeta = nextRoute.matched?.length
    ? // @ts-ignore
    nextRoute.matched[0].components?.default?.options?.meta
    : {}
  routeMeta = { ...routeMeta, ...matchedMeta }
  return routeMeta
}
