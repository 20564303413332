import Vue from 'vue'

export function loadScript(src: string, id: string) {
  const firstScript = document.getElementsByTagName('script')[0]
  if (document.getElementById(id)) return
  const newScript = document.createElement('script')
  newScript.id = id
  newScript.src = src
  newScript.async = true
  newScript.defer = true
  firstScript.parentNode!.insertBefore(newScript, firstScript)
}

export function unloadScript(id: string) {
  const el = document.querySelector(`#${id}`)
  if (!el) return
  document.head.removeChild(el)
}

export function loadScriptWithPromise(src: string, id: string) {
  if (document.getElementById(id)) return
  return new Promise((resolve, reject) => {
    let s
    s = document.createElement('script')
    s.src = src
    s.onload = resolve
    s.onerror = reject
    document.head.appendChild(s)
  })
}

export type LoadScript = typeof loadScript
export type UnloadScript = typeof unloadScript

Vue.prototype.$loadScript = loadScript
Vue.prototype.$loadScriptWithPromise = loadScriptWithPromise
Vue.prototype.$unloadScript = unloadScript
