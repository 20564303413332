interface SentryContext {
  name: string
  context: any
}

type SentryTags = Record<string, string>[]

interface Params {
  tags?: SentryTags
  context?: SentryContext
}

export class ErrorHandler {
  tag(tag: Record<string, string>) {
    return this.paramBuilder({}, {}).tag(tag)
  }

  context(context: SentryContext) {
    return this.paramBuilder({}, {}).context(context)
  }

  private paramBuilder(params: Params, mods: Partial<Params>) {
    const paramsMerged = { ...params, ...mods }
    return {
      ...paramsMerged,
      tag: (tag: Record<string, string>) =>
        this.paramBuilder(params, { tags: [...(params.tags || []), tag] }),
      context: (context: SentryContext) =>
        this.paramBuilder(params, { context }),
      error: (error: Error) =>
        this.error(error, paramsMerged.tags, paramsMerged.context, 'error'),
      warn: (error: Error) =>
        this.error(error, paramsMerged.tags, paramsMerged.context, 'warning'),
    }
  }

  error(
    errorOrAny: Error | any,
    tags?: SentryTags,
    context?: SentryContext,
    level: string = 'error',
  ) {
    console.error(errorOrAny, tags, context, level)
  }
}
