import { interpret } from 'xstate';
import { defineStore } from 'pinia';
import { getAppMenuStateMachine } from '../apps/app-menu/machine';
import { ref } from 'vue';
export var useAppMenuStore = defineStore('appMenu', () => {
  var machine = getAppMenuStateMachine();
  var states = ref([]);
  var service = interpret(machine).start().onTransition(state => {
    states.value = state.toStrings();
  });
  function toggleAccountMenu() {
    states !== null && states !== void 0 && states.value.includes('accountMenuVisible') ? service.send('HIDE_MENU') : service.send('SHOW_ACCOUNT_MENU');
  }
  function toggleAppMenu() {
    states !== null && states !== void 0 && states.value.includes('appMenuVisible') ? service.send('HIDE_MENU') : service.send('SHOW_APP_MENU');
  }
  function hideMenu() {
    service.send('HIDE_MENU');
  }
  function showAccountMenu() {
    service.send('SHOW_ACCOUNT_MENU');
  }
  var appMenuVisible = computed(() => states.value.includes('appMenuVisible'));
  var accountMenuVisible = computed(() => states.value.includes('accountMenuVisible'));
  var menuVisible = computed(() => appMenuVisible.value || accountMenuVisible.value);
  return {
    send: service.send,
    states,
    toggleAppMenu,
    toggleAccountMenu,
    hideMenu,
    accountMenuVisible,
    appMenuVisible,
    menuVisible,
    showAccountMenu
  };
});