import { defineStore } from 'pinia';
import { isLocalStorageAvailable } from '~/utility/isLocalStorageAvailable';
export var useSeenCampsitesStore = defineStore('seenCampsitesStore', () => {
  var storageKey = 'seenMapCampsites';
  var slugs = ref(new Set());
  var seenCampsites = ref([]);
  var nowTimestamp = Date.now();
  function get() {
    return Array.from(slugs.value);
  }
  function resetStorage() {
    if (!isLocalStorageAvailable) {
      return;
    }
    var data = JSON.parse(localStorage.getItem(storageKey) || 'null');
    if (data === null || keyExpired(data.timestamp)) {
      localStorage.removeItem(storageKey);
      localStorage.setItem(storageKey, JSON.stringify(getEmptyStorageObj()));
    } else {
      data.timestamp = nowTimestamp;
      localStorage.setItem(storageKey, JSON.stringify(data));
    }
  }
  function keyExpired(key) {
    var diff = nowTimestamp - key;
    // 1 day = 24hours*60min*60sec*1000millisec
    var expirationLength = 24 * 60 * 60 * 1000;
    return diff > expirationLength;
  }
  function getEmptyStorageObj() {
    return {
      timestamp: nowTimestamp,
      slugs: []
    };
  }
  function getSlugsFromStorage() {
    if (!isLocalStorageAvailable) {
      return new Set();
    }
    var storageObj = JSON.parse(localStorage.getItem(storageKey));
    return new Set(storageObj === null || storageObj === void 0 ? void 0 : storageObj.slugs);
  }
  function add(slug) {
    if (!isLocalStorageAvailable) {
      return;
    }
    slugs.value.add(slug);
    var storageObj = JSON.parse(localStorage.getItem(storageKey));
    storageObj.slugs.push(slug);
    localStorage.setItem(storageKey, JSON.stringify(storageObj));
    seenCampsites.value = get();
  }
  onMounted(() => {
    resetStorage();
    slugs.value = getSlugsFromStorage();
    seenCampsites.value = get();

    // This is required to sync changes across browser tabs
    // otherwise stores will become out of sync
    window.addEventListener('storage', event => {
      if (event.key === storageKey) {
        slugs.value = getSlugsFromStorage();
        seenCampsites.value = get();
      }
    });
  });
  return {
    seenCampsites,
    add,
    slugs,
    resetStorage
  };
});