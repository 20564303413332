import { getLocaleInfo } from '~~/lang/locales'

export class NumberParser {
  private _group: RegExp
  private _decimal: RegExp
  private _numeral: RegExp
  private _index: (d: string) => string

  constructor(locale?: string) {
    // if no locale specified, try get it from the url
    if (!locale) {
      const urlPrefix =
        typeof window !== 'undefined'
          ? window.location.pathname.split('/')[1]
          : 'en-gb'
      const localeInfo = getLocaleInfo(urlPrefix)
      locale = localeInfo ? localeInfo.iso : 'en-GB'
    }
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6)
    const numerals = [
      ...new Intl.NumberFormat(locale, { useGrouping: false }).format(
        9876543210,
      ),
    ].reverse()
    const index = new Map(numerals.map((d, i) => [d, i]))
    this._group = new RegExp(
      `[${(parts.find((d) => d.type === 'group') || { value: '' }).value}]`,
      'g',
    )
    this._decimal = new RegExp(
      `[${(parts.find((d) => d.type === 'decimal') || { value: '' }).value}]`,
    )
    this._numeral = new RegExp(`[${numerals.join('')}]`, 'g')
    this._index = (d) => (index.get(d) || 0).toString()
  }

  parse(string: string | number): number {
    if (typeof string === 'number') {
      return string
    }
    string = string
      .trim()
      .replace(this._group, '')
      .replace(this._decimal, '.')
      .replace(this._numeral, this._index)
    return string ? +string : NaN
  }

  isAllowedCharacter(character: string): boolean {
    if (this._group.test(character)) {
      return true
    }
    if (this._decimal.test(character)) {
      return true
    }
    if (this._numeral.test(character)) {
      return true
    }
    return false
  }
}
