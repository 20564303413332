import { getAuthService } from '@/apps/auth/service';
import { defineStore } from 'pinia';
import { ref } from 'vue';
export var useAuthStore = defineStore('authStore', () => {
  var isLoggedIn = ref(false);
  var userEmail = ref('');
  var authCheckResolve;
  var authCheck = new Promise(resolve => {
    authCheckResolve = resolve;
  });
  var authService = null;
  onBeforeMount(() => {
    authService = getAuthService();
    isLoggedIn.value = authService.state.matches('loggedIn');
    authService.onTransition(state => {
      var _state$context;
      // this double if is so the transition state doesn't trigger
      if (state.matches('loggedIn')) {
        isLoggedIn.value = true;
        authCheckResolve();
      } else if (state.matches('loggedOut')) {
        isLoggedIn.value = false;
        authCheckResolve();
      }
      userEmail.value = ((_state$context = state.context) === null || _state$context === void 0 ? void 0 : _state$context.email) || '';
    });
  });
  function logOutInPlace() {
    authService.send('LOG_OUT');
  }
  return {
    isLoggedIn,
    authCheckResolve,
    authCheck,
    logOutInPlace,
    userEmail
  };
});