var plugin = (ctx, inject) => {
  if (process.server) {
    ctx.res.setHeader('Vary', 'x-ua-device');
    var uaDevice = ctx.req.headers['x-ua-device'];
    var isDesktop = uaDevice !== 'mobile';
    inject('isDesktop', isDesktop);
    var script = "window.isDesktop = ".concat(isDesktop, ";");
    if (ctx.app.head) {
      // @ts-ignore
      ctx.app.head.script = ctx.app.head.script || [];
      // @ts-ignore
      ctx.app.head.script.push({
        hid: 'ua-device',
        innerHTML: script
      });
      // @ts-ignore
      ctx.app.head.__dangerouslyDisableSanitizersByTagID =
      // @ts-ignore
      ctx.app.head.__dangerouslyDisableSanitizersByTagID || {};
      // @ts-ignore
      ctx.app.head.__dangerouslyDisableSanitizersByTagID['ua-device'] = ['innerHTML'];
    }
  }
  if (process.client) {
    var _isDesktop =
    // @ts-ignore
    typeof window.isDesktop !== 'undefined' ?
    // @ts-ignore
    window.isDesktop : !navigator.userAgent.includes('Mobile');
    inject('isDesktop', _isDesktop);
  }
};
export default plugin;