import { IsoDateFormatter } from './IsoDateFormatter'

export const MINUTE = 60
export const HOUR = 60 * MINUTE
export const DAY = 24 * HOUR
export const WEEK = 7 * DAY

export function addDays(days: number, date?: string) {
  const result = date ? new Date(date) : new Date()
  result.setDate(result.getUTCDate() + days)
  return IsoDateFormatter.toIsoDate(result)
}

export function getMonthDifferenceBetweenTwoDates(
  startDate: Date,
  endDate: Date,
) {
  return (
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear())
  )
}

export function subtractDays(days: number, date?: string) {
  const result = date ? new Date(date) : new Date()
  result.setDate(result.getDate() - days)
  return IsoDateFormatter.toIsoDate(result)
}

export function addMonths(months: number, date = new Date()) {
  return new Date(date.setUTCMonth(date.getUTCMonth() + months))
}

export function daysBetween(fromDate: string | Date, toDate?: string | Date) {
  const start = new Date(fromDate)
  const end = toDate ? new Date(toDate) : new Date()
  const MS_PER_DAY = 1000 * 60 * 60 * 24
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())
  const utc2 = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate())
  return Math.abs(Math.floor((utc2 - utc1) / MS_PER_DAY))
}

export function secondsBetween(
  fromDate: string | Date,
  toDate?: string | Date,
): number {
  const start = new Date(fromDate)
  const end = toDate ? new Date(toDate) : new Date()
  return Math.floor((Number(end) - Number(start)) / 1000)
}

export function olderThan(date: string, seconds: number) {
  return secondsBetween(date, new Date()) > seconds
}

export function checkDatesAreTheSame(firstDate: Date, secondDate: Date) {
  return firstDate.toDateString() === secondDate.toDateString()
}

export function timeBetween(fromDate: string, toDate?: string) {
  const seconds = secondsBetween(fromDate, toDate)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const months = Math.floor(days / 30)
  const years = Math.floor(days / 365)

  if (years > 0) {
    return { unit: 'years', value: years }
  } else if (months > 0) {
    return { unit: 'months', value: months }
  } else if (days > 0) {
    return { unit: 'days', value: days }
  } else if (hours > 0) {
    return { unit: 'hours', value: hours }
  } else if (minutes > 0) {
    return { unit: 'minutes', value: minutes }
  }
  return { unit: 'seconds', value: seconds }
}

export function isDateInSameYear(date: string) {
  const currentDate = new Date()
  const lookupDate = new Date(date)

  return currentDate.getFullYear() === lookupDate.getFullYear()
}

export function isDatePast(date: string | Date) {
  const currentDate = new Date()
  const lookupDate = new Date(date)

  return lookupDate < currentDate
}

export function isDayPast(date: string | Date) {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const lookupDate = new Date(date)
  lookupDate.setHours(0, 0, 0, 0)
  return lookupDate < today
}
