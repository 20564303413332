import { TRANSFORMATIONS } from './transformations'

export interface PhotoTransforms {
  masterImage: string
}

function getTransformedImageUrl(url: string, transform: string) {
  const host = process.env.imagesHost
  if (!transform) {
    return url
  }
  return `${host}/_cfi/cdn-cgi/image/${transform}${url}`
}

type TransformationKey = keyof typeof TRANSFORMATIONS

export function getTransformObject<T>(
  transform: TransformationKey,
  mainImageUrl: string,
): T {
  return Object.entries(TRANSFORMATIONS[transform]).reduce(
    (accumulator, [key, trans]) => {
      accumulator[key] = getTransformedImageUrl(mainImageUrl, trans)
      return accumulator
    },
    {} as any,
  )
}
