import { Plugin } from '@nuxt/types'

// inserts the SpeedCurve RUM script into the head of the document
const rumPlugin: Plugin = (ctx) => {
  if (ctx.$config.public.disableRUM === 'true') return
  if (process.client) return // Client-side, do nothing
  ctx.beforeNuxtRender(() => {
    const rumScript = `
        LUX=function(){function n(){return Date.now?Date.now():+new Date}var r,e=n(),t=window.performance||{},a=t.timing||{navigationStart:(null===(r=window.LUX)||void 0===r?void 0:r.ns)||e};function o(){return t.now?(r=t.now(),Math.floor(r)):n()-a.navigationStart;var r}(LUX=window.LUX||{}).ac=[],LUX.addData=function(n,r){return LUX.cmd(["addData",n,r])},LUX.cmd=function(n){return LUX.ac.push(n)},LUX.getDebug=function(){return[[e,0,[]]]},LUX.init=function(){return LUX.cmd(["init"])},LUX.mark=function(){for(var n=[],r=0;r<arguments.length;r++)n[r]=arguments[r];if(t.mark)return t.mark.apply(t,n);var e=n[0],a=n[1]||{};void 0===a.startTime&&(a.startTime=o());LUX.cmd(["mark",e,a])},LUX.markLoadTime=function(){return LUX.cmd(["markLoadTime",o()])},LUX.measure=function(){for(var n=[],r=0;r<arguments.length;r++)n[r]=arguments[r];if(t.measure)return t.measure.apply(t,n);var e,a=n[0],i=n[1],u=n[2];e="object"==typeof i?n[1]:{start:i,end:u};e.duration||e.end||(e.end=o());LUX.cmd(["measure",a,e])},LUX.send=function(){return LUX.cmd(["send"])},LUX.ns=e;var i=LUX;if(window.LUX_ae=[],window.addEventListener("error",(function(n){window.LUX_ae.push(n)})),window.LUX_al=[],"function"==typeof PerformanceObserver&&"function"==typeof PerformanceLongTaskTiming){var u=new PerformanceObserver((function(n){for(var r=n.getEntries(),e=0;e<r.length;e++)window.LUX_al.push(r[e])}));try{u.observe({type:"longtask"})}catch(n){}}return i}();
        (function() {
            var script = document.createElement('script');
            script.src = 'https://cdn.speedcurve.com/js/lux.js?id=4550393677';
            script.defer = true;
            script.crossOrigin = 'anonymous';
            document.head.appendChild(script);
        })();
    `.trim()
    ctx.app.head.script.unshift({
      innerHTML: rumScript,
      type: 'text/javascript',
      hid: 'speedcurve-rum-script1',
    })
    ctx.app.head.__dangerouslyDisableSanitizersByTagID =
      ctx.app.head.__dangerouslyDisableSanitizersByTagID || {}
    ctx.app.head.__dangerouslyDisableSanitizersByTagID[
      'speedcurve-rum-script1'
    ] = ['innerHTML']
  })
}

export default rumPlugin
