
export const update = async (to?: Route) => {
  // localStorage.setItem(UA_KEY, '')
  await Promise.all([clearCache(), unregisterServiceWorkers()])
  if (to) {
    window.location = to.fullPath
  } else {
    window.location.reload()
  }
}

const clearCache = async () => {
  if ('caches' in window) {
    const cacheNames = await caches.keys()
    await Promise.all(cacheNames.map((cacheName) => caches.delete(cacheName)))
  }
}

const unregisterServiceWorkers = async () => {
  if ('serviceWorker' in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations()
    await Promise.all(
      registrations.map((registration) => registration.unregister()),
    )
  }
}
