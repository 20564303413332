import { getUserDetailService } from '../apps/user-details/service'
import { Component, Mixins, Watch } from '~/utility/pu-class-decorator'
import RequireAuthMixin from '~/mixins/RequireAuthMixin'
import { UserDetailsMachineInterpreter } from '../apps/user-details/machine'

@Component
export default class UserDetailsMixin extends Mixins(RequireAuthMixin) {
  userDetailsMachine: UserDetailsMachineInterpreter | null = null
  userDetails: UserDetailsMachineInterpreter['state']['context'] | null = null

  async authMounted() {
    if (!this.isLoggedIn) {
      this.$router.push(this.redirectUrl)
    }
    this.userDetailsMachine = await getUserDetailService()
    this.registerToUserDetailsChange()
    this.userDetails = this.userDetailsMachine?.state?.context
  }

  @Watch('isLoggedIn')
  restartUserDetailsMachine(val: boolean, prev: boolean) {
    this.userDetailsMachine?.stop()
    this.registerToUserDetailsChange()
    if (!val && prev) {
      this.$router.push(this.redirectUrl)
    }
  }

  private registerToUserDetailsChange() {
    if (this.isLoggedIn) {
      this.userDetailsMachine
        ?.onChange((context) => {
          this.userDetails = context
        })
        .start()
    }
  }
}
