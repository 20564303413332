import Vue from 'vue'
import { injectable } from '~/container'
import { vueReactiveAssign } from './vueReactiveAssign'

interface VueObservable {
  __observable: true
}

@injectable()
export class VueReactivePresenterUpdater<
  TPresenter extends Record<string, any>
> {
  private presenter: TPresenter

  constructor({ initial }: { initial: TPresenter }) {
    this.presenter = Vue.observable(initial)
  }

  getReactivePresenter(): TPresenter & VueObservable {
    return this.presenter as TPresenter & VueObservable
  }

  reactivelyUpdatePresenter(newPresenter: TPresenter) {
    vueReactiveAssign(this.presenter, newPresenter)
  }
}
