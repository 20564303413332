import sentryCapture from '~/utility/sentryCapture'
import { ErrorHandler } from './ErrorHandler'

export function reportError(
  error: Error,
  tag?: Record<string, string>,
  sentryTags: Record<string, string>[] = [],
  sentryContext?: {
    name: string
    context: any
  },
) {
  const handler = new ErrorHandler()
  if (tag) {
    handler.tag(tag).error(error)
  } else if (sentryContext) {
    handler.context(sentryContext).error(error)
  } else if (sentryTags) {
    handler.error(error, sentryTags)
  } else {
    handler.error(error)
  }
}

export const generateSentryError = (name: string, message: string) => {
  const error = new Error(message)
  error.name = name
  sentryCapture(error)
}
