export async function getUserCountry(): Promise<string> {
  try {
    const data = await $fetch<string>('/cdn-cgi/trace', {
      timeout: 30000,
      parseResponse: (txt) => txt,
    })
    return getLocationValueFromTraceResponse(data)
  } catch (error) {
    return 'GB'
  }
}

function getLocationValueFromTraceResponse(data: string): string {
  for (const line of data.split('\n')) {
    const element = line.split('=')
    if (element[0] === 'loc') return element[1]
  }
  return 'GB'
}
