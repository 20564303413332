import { onMounted, usePinia, useRequestHeaders, useRoute } from '#imports'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import {
  PrivacyType,
  WishlistFullData,
  WishlistPrimaryPhoto,
  Wishlist as WishlistType,
} from '~/apps/favourites/types'
import { FavouritesRepository } from '../apps/favourites/repositories/FavouritesRepository'
import { safeJSONParse } from '../utility/json'
import { logger } from '../utility/logger'
import { useAuthStore } from './useAuthStore'

interface WishlistItem {
  id: string
  notes: string
  created: string
}

interface Favourite {
  added?: string
  wishlists?: Record<string, WishlistItem>
}

type campsiteId = string
export type Favourites = Record<campsiteId, Favourite>
export type Wishlists = Record<string, WishlistType>

export const useFavouritesStore = defineStore('favourites', () => {
  const langCode = computed(() => useRoute().params.lang || 'en-gb')
  const favouritesData = ref<Favourite>({})
  const wishlistsData = ref<Record<string, WishlistFullData>>({})
  const guestWishlists = ref<WishlistFullData[]>([])
  const favouritesPrimaryPhoto = ref<WishlistPrimaryPhoto | null>(null)
  const authStore = useAuthStore(usePinia())
  // this will get set if the call to the server is successful
  // and then we watch isLoggedIn from the auth mixin
  const currentlyLoggedIn = ref<boolean | null>(null)
  const headers = useRequestHeaders(['cookie'])
  const repo = new FavouritesRepository(langCode.value, { headers })

  if (process.client) {
    watch(
      () => authStore.isLoggedIn,
      async (newValue) => {
        logger(
          'useFavouritesStore.isLoggedIn changed',
          newValue,
          currentlyLoggedIn.value,
        )
        if (currentlyLoggedIn.value && !newValue) {
          logger('useFavouritesStore logging out')
          await initFavourites()
          currentlyLoggedIn.value = false
        }
        if (!currentlyLoggedIn.value && newValue) {
          logger('useFavouritesStore logging in')
          // only init if we have already set the value
          if (currentlyLoggedIn.value !== null) {
            await initFavourites()
          }
          currentlyLoggedIn.value = true
        }
      },
    )
  }

  onMounted(async () => {
    logger('useFavouritesStore.onMounted')
    if (!Object.keys(favourites.value).length) {
      await getAllFavourites()
    }
    if (!guestWishlists.value.length) {
      await getAllGuestWishlists()
    }
  })

  // initialises the favourites and wishlists
  async function initFavourites() {
    logger('useFavouritesStore.initFavourites', Object.keys(wishlists.value))
    await Promise.all([
      getAllFavourites(),
      getAllWishlists(),
      process.client ? getAllGuestWishlists() : Promise.resolve([]),
    ])
    logger('useFavouritesStore.initFavourites finished')
  }

  async function initFavouritesIfEmpty() {
    if (!Object.keys(favourites.value).length) {
      await initFavourites()
    }
  }

  const count = computed(() => {
    return Object.keys(favourites.value).reduce(
      (acc, key) => ('added' in favourites.value[key] ? acc + 1 : acc),
      0,
    )
  })

  const totalCount = computed(() => {
    return Object.keys(favourites.value).length
  })

  // updates a favourite in a wishlist (the notes)
  async function updateFavouriteNoteInWishlist(
    listId: string,
    campsiteId: string,
    note: string,
  ) {
    const response = await repo.updateFavouriteInWishlist(
      listId,
      campsiteId,
      note,
    )
    await initFavourites()
    return response
  }

  // updates a wishlist details like name and status
  async function updateWishList(
    listId: string,
    updates: { name: string; status?: PrivacyType },
  ) {
    const response = await repo.updateWishlist(listId, updates)
    await initFavourites()
    return response
  }

  // adds a campsite to a wishlist
  async function addFavouriteToWishlist(
    listId: string,
    campsiteId: string,
    note: string = '',
  ) {
    const response = await repo.addFavouriteToWishlist(listId, campsiteId, note)
    await initFavourites()
    return response
  }

  // creates a new wishlist and adds a campsite to it
  async function addFavouriteToNewWishlist(
    campsiteId: string,
    listName: string,
    status: PrivacyType = 'private',
  ) {
    // add new list with campsite prefilled
    const response = await repo.addNewList(
      listName,
      [
        {
          campsite_id: parseInt(campsiteId),
        },
      ],
      status,
    )
    await initFavourites()
    return {
      listId: response.listId,
      listName,
      campsiteId,
      status,
      isOwner: true,
    }
  }

  // removes a campsite from a wishlist
  async function removeFavouriteFromWishlist(
    listId: string,
    campsiteId: string,
  ) {
    await repo.removeFromWishlist(listId, campsiteId)
    await initFavourites()
  }

  // creates a new wishlist
  async function addNewWishlist(
    name: string,
    privacy: PrivacyType = 'private',
  ) {
    const response = await repo.addNewList(name, undefined, privacy)
    await initFavourites()
    return {
      id: response.listId,
      name,
      privacy,
    }
  }

  // remove a wishlist
  async function removeWishlist(wishlistId: string) {
    await repo.removeWishlist(wishlistId)
    await initFavourites()
  }

  // add a campsite to default favourites
  async function addFavourite(campsiteId: string) {
    if (!currentlyLoggedIn.value) {
      favouritesPrimaryPhoto.value = await repo.add(campsiteId)
    } else {
      const defaultWishlist = Object.values(wishlistsData.value).find(
        (wishlist) => wishlist.isMain,
      )
      if (defaultWishlist) {
        await addFavouriteToWishlist(defaultWishlist.id, campsiteId)
      }
    }
    await initFavourites()
  }

  // remove a campsite from default favourites
  async function removeFavourite(campsiteId: string) {
    favouritesPrimaryPhoto.value = await repo.remove(campsiteId)
    await initFavourites()
  }

  // gets all the favourites for the user
  async function getAllFavourites() {
    try {
      const { favouriteDatedWithLists, favouritesPrimaryPhoto: primaryPhoto } =
        await repo.getAll()
      favouritesData.value = favouriteDatedWithLists
      favouritesPrimaryPhoto.value = primaryPhoto
    } catch (error) {
      console.error('Failed to fetch favourites:', error)
    }
  }

  // gets all the wishlists for the user
  async function getAllWishlists() {
    try {
      const response = await repo.getAllWishlists()
      if (response === false) {
        currentlyLoggedIn.value = false
        wishlistsData.value = {}
        return
      }
      currentlyLoggedIn.value = true
      wishlistsData.value = response as Record<string, WishlistFullData>
    } catch (error) {
      console.error('Failed to fetch wishlists:', error)
    }
  }

  // gets the wishlist details for the guest wishlists
  async function getAllGuestWishlists() {
    if (process.server) {
      return []
    }
    try {
      const wishlists = localStorage.getItem('guestWishlists')
      logger('useFavouritesStore.getAllGuestWishlists', wishlists)
      const wishlistArray = wishlists
        ? (safeJSONParse(wishlists) as string[])
        : []
      let newWishlistArray: string[] = [...wishlistArray]
      guestWishlists.value = (
        await Promise.all(
          wishlistArray
            .filter((wishlistId) => !wishlistsData.value[wishlistId])
            .map(async (wishlistId) => {
              const wishlistDetails =
                await repo.getDetailedCampsitesNoPagination(wishlistId)
              // remove lists we own
              if (wishlistDetails && wishlistDetails.isOwner) {
                newWishlistArray = wishlistArray.filter(
                  (id) => id !== wishlistId,
                )
                localStorage.setItem(
                  'guestWishlists',
                  JSON.stringify(newWishlistArray),
                )
              }
              return wishlistDetails && !wishlistDetails.isOwner
                ? wishlistDetails
                : null
            }),
        )
      )
        .filter((wishlist): wishlist is WishlistFullData => wishlist !== null)
        .sort((a, b) => b.itemCount - a.itemCount)
    } catch (error) {
      console.error('Failed to fetch guest wishlists:', error)
    }
    return []
  }

  // initialises a specific guest wishlist
  async function initGuestWishlist(wishlistId: string) {
    const wishlistDetails = await repo.getDetailedCampsitesNoPagination(
      wishlistId,
    )
    if (wishlistDetails && !wishlistDetails.isOwner) {
      if (!guestWishlists.value.find((list) => list.id === wishlistId)) {
        const updatedGuestWishlists = [...guestWishlists.value, wishlistDetails]
        guestWishlists.value = updatedGuestWishlists
      }
    }
    return []
  }

  // saves the guest wishlistId to local storage
  function setGuestWishlist(listId) {
    if (!listId || typeof listId !== 'string') {
      return
    }
    const guestWishlists = localStorage.getItem('guestWishlists')
    const wishlistArray = guestWishlists ? JSON.parse(guestWishlists) : []
    if (!wishlistArray.includes(listId)) {
      wishlistArray.push(listId)
      localStorage.setItem('guestWishlists', JSON.stringify(wishlistArray))
    }
  }

  // combine the wishlists with the guest wishlists
  const wishlists = computed(() => {
    const combinedWishlists = { ...wishlistsData.value }
    guestWishlists.value.forEach((wishlist) => {
      if (wishlist && wishlist.id && !combinedWishlists[wishlist.id]) {
        combinedWishlists[wishlist.id] = wishlist
      }
    })
    return combinedWishlists
  })

  // combine the favourites with the guest wishlists
  const favourites = computed(() => {
    const combinedFavourites = { ...favouritesData.value }
    guestWishlists.value.forEach((wishlist) => {
      if (wishlist && wishlist.campsites) {
        wishlist.campsites.forEach((campsite) => {
          if (!combinedFavourites[campsite.id]) {
            combinedFavourites[campsite.id] = {
              wishlists: {},
            }
          }
          if (!combinedFavourites[campsite.id].wishlists) {
            combinedFavourites[campsite.id].wishlists = {}
          }
          combinedFavourites[campsite.id].wishlists[wishlist.id] = {
            id: wishlist.id,
            notes: campsite.wishlistItems?.[wishlist.id]?.notes,
            // created: campsite.wishlistItems?.[wishlist.id]?.created,
          }
        })
      }
    })
    return combinedFavourites
  })

  const myWishlists = computed(() => {
    // TODO: if need be put the main wishlist at the top
    return Object.values(wishlistsData.value).sort(
      (a, b) => b.itemCount - a.itemCount,
    )
  })

  return {
    addFavourite,
    addFavouriteToWishlist,
    addNewWishlist,
    count,
    favourites,
    favouritesPrimaryPhoto,
    removeFavourite,
    removeFavouriteFromWishlist,
    removeWishlist,
    totalCount,
    updateFavouriteNoteInWishlist,
    wishlists,
    initFavourites,
    currentlyLoggedIn,
    guestWishlists,
    getAllGuestWishlists,
    setGuestWishlist,
    getAllFavourites,
    addFavouriteToNewWishlist,
    initFavouritesIfEmpty,
    updateWishList,
    initGuestWishlist,
    myWishlists,
    wishlistsData,
    favouritesData,
  }
})
