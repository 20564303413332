import { Distance } from './Distance'
import {
  GeoCircle,
  GeoPoint,
  getGeoCircleFromPointAndRange,
} from './GeoLocation'

export function mutateAreaPoint(
  area: GeoCircle | undefined,
  point: GeoPoint | undefined,
  opts: {
    defaultWithinMiles: number
  },
): GeoCircle | undefined {
  if (!point) return undefined

  return getGeoCircleFromPointAndRange(
    point,
    area?.rangeMiles ?? opts.defaultWithinMiles,
  )
}

export function mutateAreaWithin(
  area: GeoCircle | undefined,
  within: number,
): GeoCircle {
  if (!area) throw new TypeError('You cannot set within on empty point')

  return {
    lat: area.lat,
    lng: area.lng,
    rangeMiles: Distance.new({ mi: within }).mi,
    isEmptyPoint: area.isEmptyPoint,
  }
}
