export function getCookie(key: string) {
  const reKey = new RegExp(`(?:^|; )${key}(?:=([^;]*))?(?:;|$)`)
  const match = reKey.exec(document.cookie)
  return match ? match[1] : null
}

export function setCookie(key: string, value: string, expDays: number = 365) {
  const date = new Date()
  const year = expDays * 24 * 60 * 60 * 1000
  date.setTime(date.getTime() + year)
  document.cookie = `${key}=${value}; expires=${date}; path=/; domain=${window.location.hostname}`
}
