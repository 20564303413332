import { Interpreter } from 'xstate'

export function waitForInterpreterState<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Interpreter<any, any, any>,
>({
  machine,
  errorStates,
  successStates,
}: {
  machine: T
  errorStates?: string[]
  successStates: string[]
}): Promise<T['state']> {
  return new Promise((resolve, reject) => {
    const isSuccessState = (state: T['state']) =>
      successStates.findIndex((successState) => state.matches(successState)) >
      -1
    const isErrorState = (state: T['state']) =>
      errorStates &&
      errorStates.findIndex((errorState) => state.matches(errorState)) > -1

    const machineListener = (state: T['state']) => {
      if (isSuccessState(state)) {
        machine.off(machineListener)
        resolve(state)
      } else if (isErrorState(state)) {
        machine.off(machineListener)
        reject(state?.context?.error)
      }
    }
    machineListener(machine.state)
    machine.onTransition(machineListener)
  })
}
